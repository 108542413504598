.view-container {
  position: relative; /* Ensure proper positioning context */
  width: 100%; /* Set the width of the parent container */
  height: 100vh; /* Set the height of the parent container */
  overflow-x: hidden; /* Hide overflowing content */
}

#viewport {
  z-index: 2;
  transition: transform 0.5s;
  right:0;
  background-color: white;
  position: absolute;
  width: 70%;
  height: 100vh;
  transform: translateX(300%); /* Start outside the visible area */
}

#viewport.active {
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

#viewport section {
  background-color: #fff;
  position: absolute;
  padding: 25px;
  padding-top:100px;
  width: 100%;
}

.section-content {
  margin-left: 250px;
}

.-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4;
  color: #000;

  margin-bottom: 2.2rem;
}

.-descricao {
  font-style: normal;
  font-weight: 200;
  font-size: 0.84rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  text-align: justify;
  text-justify: inter-word;
}

.faq-wrapper span {
  display: none;
  transition: transform 0.5s;
}

.faq-wrapper span#active {
  display: grid;
  animation: slide-in 0.5s forwards;
  transform: translateX(300%);
}

.faq-wrapper div.faq-answer.lang-pt {
  display: none;
  transition: transform 0.5s;
}

.faq-wrapper div.faq-answer.lang-en {
  display: none;
  transition: transform 0.5s;
}

.faq-wrapper div#active {
  display: grid;
  animation: slide-in 0.5s forwards;
  transform: translateX(300%);
  text-align: justify;
  text-justify: inter-word;
}

.faq-wrapper .item .item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding: 13px 0;

}

.faq-wrapper .item .faq-answer {
  margin-top: 10px;
}

.titulo-wrapper {
  margin-bottom: 3rem;
  font-size: 1rem;
}

.titulo-wrapper .title {
  font-family: "Lora",serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.4;
  color: #000;
}

.faq-wrapper h2 {
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;
}

.close-icon {
  color:#000;
  display: none;
}


.close-icon path {
  stroke: #000;
  transition: .2s ease-in;
}

.close-icon.active {
  display: grid;
}

.form-wrapper .input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
}

.form-wrapper .input-group label {
  line-height: 1.2;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-wrapper .input-group input {
  background: #d9d9d9;
  border-radius: 4px;
  border: none;
  height: 42px;
  padding: 0 10px;
}

.form-wrapper .input-group input {
  height: 36px;
}

.form-wrapper .input-group input, .body__home #contato .form-wrapper .input-group textarea {
  transition: box-shadow .2s ease-in;
  width: 100%;
  max-width: 100%;
}

.form-wrapper .input-group textarea {
  transition: box-shadow .2s ease-in;
  width: 100%;
  max-width: 100%;
}

.body__home #contato .form-wrapper .input-group textarea {
  background: #d9d9d9;
  border-radius: 4px;
  border: none;
}

.form-wrapper .button-wrapper button {
  border: none;
  background: rgba(50,50,50,.7);
  padding: 10px 28px;
  color: rgba(255,255,255,.8);
  font-size: 12px;
  font-family: "DM Sans",sans-serif;
  line-height: 1;
  cursor: pointer;
  transition: all .2s ease-in;
}

.form-wrapper .button-wrapper button:hover {
  background: rgba(255,255,255,.8);
  color: rgba(50,50,50,.7);
}
@import "./Shared.scss";



h3 {
    font-family: "DM Sans", sans-serif;
    font-weight: 600;
    font-size: 0.946rem;

}

body {
    font-family: "DM Sans", sans-serif;
}

.page_404 {
    font-family: "Arvo", serif;
  }
  

.locationTitle {
    font-family: $title1;
    font-size: 1.09rem;
    padding-top: 0px;
    padding-left: 50px;
    font-weight: 600;
    padding-right: 50px;
    text-transform: uppercase;
  }
  
  .title1 {
    font-family: $title1;
    //font-size: 20px;
    padding-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 300;
    background-color: $lightBackground;
  }

.form-wrapper .input-group label {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 8px;
}

.form-wrapper .input-group label {
    line-height: 1.2;
    font-size: 14px;
    margin-bottom: 5px;
}

.clickable {
  color: rgb(14, 41, 90);
  font-size: medium;
  font-weight: 300;
  position: static;
}
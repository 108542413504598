.container {
  display: grid;
  grid-template-columns: 2fr 3fr; 
}

@media (max-width: 1024px) {
  html {
    font-size: calc(var(--font-base) - 12.5%);
  }
  #viewport {
    margin-top: 90px;
    width: 100%;
    height: calc(100% - 90px);
  }
  #viewport section {
    padding-top: 40px;
}
#side-bar.active ul.nav {
  z-index: 9999;
  transform: translateX(0);
  overflow-y: auto;
  opacity: 1;
  padding-top: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: calc(100% - 20vh - 5rem);
  background-color: rgba(0, 0, 0, 0.6);
}
#side-bar .side-bar-wrapper-links .nav {
  display: block;
  position: fixed;
  width: 100%;
  top: 82px;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  transition: .3s ease-in-out;
  opacity: 0;
  overflow-y: hidden;
}
  .background-image {
    background-position: -500px;
  }
  .App-logo {
    transform: scale(0.8);
    padding-left: 0px;
    margin: -10px;
    margin-left: -10px;
  }
  #btn-mobile {
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    background: 0 0;
    cursor: pointer;
    gap: 0.5rem;
    color: #eeeae7;
  }
  #hamburger {
    border-top: 2px solid black;
}
  .nav {
    display: none;
  }
  .section-content {
    margin-left: 0;
}
  #side-bar {
    width: 100%;
    left: 0;
    right: 0;
    height: auto;
    margin-left: 0;
    background: transparent;
  }
  #side-bar .side-bar-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 82px;
    padding: 10px 1.5rem;
  }
  #side-bar .side-bar-wrapper-links {
    margin-top: unset;
  }
}

@media (max-width: 980px) {
  html {
    font-size: calc(var(--font-base) - 12.5%);
  }
}

@media (max-height: 590px) {
  .background-image {
    background-position: -500px;
    background-size: 1500px;
  }
}

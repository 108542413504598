#side-bar {
    z-index: 1;
    position: fixed;
    left: 285px;
    width: 30vw;
    height: 100%;
    margin-left: -285px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
}

#btn-mobile {
    display: none;
  }

.side-bar-wrapper {
    padding-top: 2rem;
    padding-left: 5rem;
    padding-bottom: 5rem;
    padding-right: 1.875rem;
    height: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.side-bar-wrapper-links {
    margin-top: auto;
}

.side-bar-wrapper .nav .link {
    margin-bottom: 10px;
    cursor: pointer;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    box-sizing: border-box;
}

ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}


.side-bar-wrapper .nav .link.active p {
    font-style: italic;
    text-decoration: underline;
}

#side-bar .side-bar-wrapper .nav .link p:hover {
    text-decoration: underline;
    transition: all .2s ease;
}

.side-bar-wrapper .nav .link p {
    text-decoration: none;
    color: white;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    display: block;
    font-size: 18px;
    font-family: "DM Sans",sans-serif;
    letter-spacing: .04em;
    line-height: 1.3;
    position: relative;
    width: max-content;
    padding-bottom: 0;
    transition: 300ms ease-in;
}

#hamburger::after, #hamburger::before {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background-color: black;
    margin-top: 5px;
    transition: .3s;
    position: relative;
}



.shake-effect {
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
      border: 1px solid red;
    }
    25%, 75% {
      transform: translateX(-10px);
      border: 1px solid red;
    }
    50% {
      transform: translateX(10px);
      border: none;
    }
  }
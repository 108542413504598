@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap&family=Lora&display=swap");

/*Colors*/
$lightBackground: #eaeaea;
$lightTextColor: white;
$linkColor: #61dafb;
$borderColor: rgba(50, 50, 50, 0.43);


/*Typo*/
$title1: "Inter", sans-serif;
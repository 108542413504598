@import "./Shared.scss";

* {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: $lightBackground;
  font-size: 0.84rem;
  font-weight: 200;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  --font-base: 100%;
}

button {
  appearance: auto;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0em;
  padding: 1px 6px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

body,
p,
h1,
h2,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
  color: #000;
}

.App {
  background-color: transparent;
  flex-direction: column;
  display: grid;
}

.App-logo {
  padding-left: 25px;
  padding-top: 10px;
  min-width: 50px;
  max-width: 250px;
  transform: filter 0.2 ease-in-out;
}

.App-header {
  background-color: transparent;
}

.background-image {
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
}

.locationBar {
  z-index: 0;
  background: linear-gradient(to right, transparent, black);
  position: absolute;
  display: flex;
  //font-size: 22px;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  min-height: 10vh;
  min-width: 400px;
  right: 0;
  bottom: 0;
}

.newLocation {
  filter: invert(1);
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.locationIco {
  max-width: 30px;
  min-width: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.subtitle1 {
  font-size: 16px;
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 5px;
  font-weight: 200;
  padding-bottom: 0px;
}

div .contact {
  justify-content: space-between;
}

.contact a {
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 10px;
}


.contact p.lang-pt {
  margin-top: 12px;
}

.contact p.lang-en {
  margin-top: 12px;
}

.contactSession {
  margin-top: 50px;
  align-items: center;
  flex-direction: row;
}

.whatsappBtn {
  border: 0px solid;
  align-items: center;
  padding: 0px;
}

.whatsappBtn:hover {
  box-shadow: 0px 0px 1px black;
}

.contactIco {
  max-width: 25px;
  padding-left: 0px;
}

.App-link {
  color: $linkColor;
}

footer {
  align-items: center;
  text-align: center;
}

.menu {
  background: linear-gradient(to left, transparent, black);
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 200px;
  align-items: center;
  text-align: center;
  color: $lightTextColor;
}

.page_404 {
  position: absolute;
  align-items: center;
  text-align: center;
  padding: 40px 0;
  background: #fff;
  width: 100vw;
  height: 100vh;
}

.page_404 .container {
  display: inline;
  height: 100vh;
  padding: 150px 0;
}
.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
   height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

